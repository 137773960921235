import React, { useContext } from "react";
import { Chevron } from "../../../assets/icons";
import CustomButton from "../../CustomButton";
import HeadingSection from "../HeadingSection";
import profile from "../../../assets/defaultTestimonialAvatar.svg";
import ModalContext from "../../../Contexts/openModal";
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image";

const TestimonialMonetCard = ({ data }) => {
  const { toggleModal } = useContext(ModalContext);
  const emptyHeading = !!(
    data?.primary?.caption_title?.text ||
    data?.primary?.show_cta ||
    data?.primary?.description?.text ||
    data?.primary?.title?.text
  );
  return (
    <div
      className={` text-white max-w-[798px] w-auto mx-auto rounded-lg sm:px-12 lg:px-0 px-4`}
    >
      {emptyHeading && (
        <div className="flex justify-center">
          <HeadingSection
            data={data?.primary}
            headingStyle={`${data?.primary?.theme === "Dark" ? "text-white" : " text-purple-1100"} text-center lg:text-left`}
            sectionStyle={"items-center sm:!pb-10"}
            labelStyle={`${data?.primary?.theme === "Dark" ? "text-purple-350" : " text-purple-1500"}`}
            toggleModal={toggleModal}
          />
        </div>
      )}
      <div className="flex items-center gap-2.5 sm:hidden mb-5">
        {/* <img
          // image={getImage(data?.items?.author_image)}
          src={data?.items[0]?.author_image?.url || profile}
          alt="Profile"
          loading="lazy"
          width={76}
          height={76}
          className=" sm:w-[76px] w-[60px] rounded-full "
        /> */}
        {data?.items[0]?.author_image?.url ? (
          // <GatsbyImage
          //   width={76}
          //   height={76}
          //   image={getImage(data?.items[0]?.author_image || profile)}
          //   alt="Profile"
          //   loading="lazy"
          //   fetchPriority="high"
          //   sizes="60px"
          //   className=" sm:w-[76px] w-[60px] rounded-full "
          // />
          <img
            src={data?.items[0]?.author_image?.url || profile}
            alt="Profile"
            loading="lazy"
            width={76}
            height={76}
            className=" sm:w-[76px] w-[60px] rounded-full "
          />
        ) : (
          <StaticImage
            height={100}
            width={100}
            src={"../../../assets/defaultTestimonialAvatar.svg"}
            alt="Profile"
            loading="lazy"
            izes="60px"
            className=" sm:w-[76px] w-[60px] rounded-full "
          />
        )}
        <div className="">
          <p
            className={`text-sm sm:text-lg font-semibold ${data?.primary?.theme === "Dark" ? "text-white" : "text-gray-2700"} `}
          >
            {data?.items[0]?.author_name?.text}
          </p>
          <p
            className={`text-xs sm:text-base font-normal ${data?.primary?.theme === "Dark" ? "text-gray-1400" : "text-gray-600"} max-w-[240px]`}
          >
            {data?.items[0]?.author_job_designation?.text}
          </p>
        </div>
      </div>
      <div className={`max-w-[718px] mx-auto p-0 mb-5 sm:mb-2.5`}>
        <p
          className={`lg:px-10 sm:px-8 px-6 lg:py-9 py-6 sm:py-6 font-medium sm:text-xl text-lg md:tracking-[-0.4px] sm:tracking-[-0.5px] tracking-[-0.4px] ${data?.primary?.theme === "Dark" ? "bg-[#FAFAFA]  " : "bg-[#F0F5FF] "}  text-[#262626] rounded-tl-[2px] sm:rounded-tl-[14px] rounded-tr-[14px] rounded-br-[14px] rounded-bl-[14px] sm:rounded-bl-[2px] lg:rounded-bl-[2px]`}
        >
          {data?.items[0]?.testimonial_text?.text}
        </p>
      </div>

      <div className="flex items-center justify-between">
        <div className="sm:flex items-center gap-2.5 hidden">
          {data?.items[0]?.author_image?.url ? (
            // <GatsbyImage
            //   height={100}
            //   width={100}
            //   image={getImage(data?.items[0]?.author_image || profile)}
            //   alt="Profile"
            //   loading="lazy"
            //   fetchPriority="high"
            //   sizes="76px"
            //   className=" sm:w-[76px] w-[60px] rounded-full "
            // />
            <img
              height={100}
              width={100}
              src={data?.items[0]?.author_image?.url || profile}
              alt="Profile"
              loading="lazy"
              className=" sm:w-[76px] w-[60px] rounded-full "
            />
          ) : (
            <StaticImage
              height={100}
              width={100}
              src={"../../../assets/defaultTestimonialAvatar.svg"}
              alt="Profile"
              loading="lazy"
              sizes="76px"
              className=" sm:w-[76px] w-[60px] rounded-full "
            />
          )}
          <div className="">
            <p
              className={`text-sm sm:text-lg font-semibold ${data?.primary?.theme === "Dark" ? "text-white" : "text-gray-2700"}`}
            >
              {data?.items[0]?.author_name?.text}
            </p>
            <p
              className={`text-xs sm:text-base font-normal ${data?.primary?.theme === "Dark" ? "text- gray-1400" : "text-gray-600"} max-w-[320px]`}
            >
              {data?.items[0]?.author_job_designation?.text}
            </p>
          </div>
        </div>
        {data?.items[0]?.show_cta && (
          <CustomButton
            link={data?.items[0]?.cta_link?.url}
            text={data?.items[0]?.cta_text?.text}
            buttonClass="bg-gray-1600 text-gray-2500 mx-auto sm:mx-0 w-full lg:w-auto"
            icon={<Chevron />}
            direction="right"
          />
        )}
      </div>
    </div>
  );
};

export default TestimonialMonetCard;
