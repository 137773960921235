import React, { useEffect, useRef } from "react";
import "../../../styles/components/Slices/sectionDali.scss";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import CustomButton from "../../CustomButton";
import { Chevron } from "../../../assets/icons";
import HeadingSection from "../../Pieces/HeadingSection";
import useWindowWidth from "../../../hooks/useBetterMediaQuery";

export const FeatureMunch = ({ slice }) => {
  const isDark = slice?.primary?.theme === "dark";
  const notEmpty =
    slice?.primary?.caption_title?.text ||
    slice?.primary?.title?.text ||
    slice?.primary?.description?.text ||
    slice?.primary?.cta_text?.text;
  const titleRefs = useRef([]);
  const isMobile = useWindowWidth(475);
  const isTablet = useWindowWidth(768);

  useEffect(() => {
    if (isMobile && titleRefs.current.length) {
      const maxHeight = Math.max(
        ...titleRefs.current.slice(1).map((el) => el?.offsetHeight || 0)
      );

      titleRefs.current.forEach((el, index) => {
        if (el) {
          el.style.height =
            index === 0 && !isTablet ? "auto" : `${maxHeight}px`;
        }
      });
    } else {
      titleRefs.current.forEach((el) => {
        if (el) el.style.height = "auto";
      });
    }
  }, [slice?.items, isMobile, isTablet]);

  return (
    <section className={`relative px-4  ${isDark ? "bg-purple-2300" : ""} `}>
      <article className="py-10 lg:py-20 lg:mx-11">
        <article className="relative max-w-6xl xl:px-0 xl:mx-auto sm:mx-6">
          {notEmpty && (
            <HeadingSection
              headingStyle={`${isDark ? "text-white" : "text-purple-1100"}`}
              labelStyle={`bg-[#F2F0FF] text-xs px-3.5 py-1.5 rounded-md uppercase text-purple-1100 font-manrope font-semibold`}
              sectionStyle="sm:!pb-10"
              data={slice?.primary}
            />
          )}
          <section className="grid grid-cols-1 lg:grid-cols-3 sm:grid-cols-2 sm:gap-x-6 sm:gap-y-14 gap-y-12 ">
            {slice?.items?.map(
              (
                {
                  caption_title,
                  header_title,
                  show_cta,
                  cta_text,
                  cta_link,
                  descriptor_text,
                  image,
                },
                index
              ) => {
                return (
                  <div
                    key={index}
                    className={`flex flex-col text-white lg:gap-y-6 ${
                      slice?.items?.length === 3
                        ? index === 0
                          ? "sm:col-span-2 lg:col-span-1 gap-y-3.5"
                          : "gap-y-3.5"
                        : "gap-y-3.5"
                    }`}
                  >
                    {image?.url && (
                      <div
                        className={`lg:py-0 flex justify-center ${isDark ? "bg-purple-2300" : " "} `}
                        active_features
                      >
                        <GatsbyImage
                          // src={image?.url}
                          loading="eager"
                          fetchPriority="high"
                          sizes="(max-width:1024px)308px,100vw"
                          image={getImage(image)}
                          alt={image?.alt || "feature munch image"}
                          className={`custom-gatsby sm:w-full lg:w-auto w-auto border-none  
                            ${index === 0 ? "sm:w-auto" : "sm:max-h-[270px]"}  
                            lg:min-h-[380px] lg:max-h-[380px]`}
                        />
                        {/* <img
                          loading="eager"
                          img={image?.url}
                          alt={image?.alt || "feature munch image"}
                          className={`custom-gatsby sm:w-full lg:w-auto w-auto border-none  
                            ${index === 0 ? "sm:w-auto" : "sm:max-h-[270px]"}  
                            lg:min-h-[380px] lg:max-h-[380px]`}
                        /> */}
                      </div>
                    )}
                    <div className="h-full flex flex-col  items-start">
                      {caption_title?.text && (
                        <p
                          className={`mb-4 text-xs font-medium uppercase font-inter lg:text-base tracking-widest_1  ${isDark ? "text-purple-350 " : "!text-purple-1500"}`}
                        >
                          {caption_title?.text}
                        </p>
                      )}
                      {header_title?.text && (
                        <p
                          ref={(el) => (titleRefs.current[index] = el)}
                          className={`sm:text-2xl text-2xl font-semibold ${!descriptor_text?.text ? "mb-0" : "mb-5"}  h-auto font-manrope !tracking-tightest_5 ${isDark ? "text-white " : "text-purple-1100"}`}
                        >
                          {header_title?.text}
                        </p>
                      )}
                      {descriptor_text?.text && (
                        <p
                          className={`text-base font-inter leading-6 lg:mt-0 tracking-tight_1 ${isDark ? "text-gray-2200" : "text-gray-600"}`}
                        >
                          {descriptor_text?.text}
                        </p>
                      )}
                    </div>
                    {show_cta && (
                      <div className="mt-auto">
                        <CustomButton
                          direction={`right`}
                          icon={<Chevron />}
                          buttonClass={`${isDark ? "bg-gray-1500 " : "bg-gray-1600"} hover:bg-gray-2900 text-gray-2500`}
                          text={cta_text?.text}
                          link={cta_link?.url}
                        />
                      </div>
                    )}
                  </div>
                );
              }
            )}
          </section>
        </article>
      </article>
    </section>
  );
};
