import React, { useState, useEffect, useRef } from "react";
import "../../../styles/components/Slices/SectionMoore.scss";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import useWindowWidth from "../../../hooks/useBetterMediaQuery";
import SlicesVideoComponent from "../SlicesVideoComponent";
import posterVideo from "../../../assets/videoPoster.png";
import { Link } from "@reach/router";

const SectionMoore = ({ slice, location }) => {
  const topSpacing = slice?.primary?.top_spacing;
  const bottomSpacing = slice?.primary?.bottom_spacing;
  const [activeIndex, setActiveIndex] = useState(0);
  const itemsLength = slice?.items?.length || 0;
  const isDesktop = useWindowWidth(1023);
  const isTablet = useWindowWidth(520);
  const intervalRef = useRef(null);

  useEffect(() => {
    startTimer();
    return () => clearInterval(intervalRef.current);
  }, [itemsLength]);

  const startTimer = () => {
    clearInterval(intervalRef.current);
    intervalRef.current = setInterval(() => {
      setActiveIndex((prevIndex) => (prevIndex + 1) % itemsLength);
    }, 7000);
  };

  const handleMouseEnter = (index) => {
    if (!isDesktop) return;
    clearInterval(intervalRef.current);
    setActiveIndex(index);
  };

  const handleMouseLeave = () => {
    startTimer();
  };

  return (
    <section className="relative px-4 mx-auto sm:px-12 lg:px-5 max-w-7xl">
      <article
        className={`relative lg:mx-11 ${topSpacing === "80" && "sm:pt-20 pt-8"} ${topSpacing === "40" && "sm:pt-10 pt-8"} ${topSpacing === "0" && "pt-0"} ${bottomSpacing === "80" && "sm:pb-20 pb-8"} ${bottomSpacing === "40" && "sm:pb-10 pb-8 "} ${bottomSpacing === "0" && "pb-0"}`}
      >
        <div className="flex gap-10">
          <div className="lg:flex-[0.5] ">
            <h2
              role="heading"
              aria-level="2"
              className="font-manrope font-semibold text-3.25xl leading-10 tracking-tightest_7 text-purple-1100 heading-strong-style pb-10"
              dangerouslySetInnerHTML={{
                __html: slice?.primary?.heading?.html,
              }}
            />
            <div className="flex flex-col gap-2.5">
              {slice?.items?.map((item, index) => (
                <>
                  {!isDesktop &&
                    !isTablet &&
                    activeIndex === index &&
                    (slice?.items[activeIndex]?.video_asset?.url ? (
                      <div className="animate-fade-in-up opacity-0">
                        <SlicesVideoComponent
                          src={slice?.items[activeIndex]?.video_asset?.url}
                          posterImage={posterVideo}
                          className="rounded-[4px]"
                        />
                      </div>
                    ) : (
                      <div
                        data-aos="fade"
                        className="h-full opacity-0 animate-fade-in-up"
                      >
                        {/* <GatsbyImage
                          image={getImage(
                            slice?.items[activeIndex]?.image_asset
                          )}
                          loading="eager"
                          fetchPriority="high"
                          width={0}
                          height={0}
                          alt="stats"
                          className="w-full rounded-[4px] h-full"
                        /> */}
                        <img
                          src={slice?.items[activeIndex]?.image_asset?.url}
                          loading="eager"
                          width={0}
                          height={0}
                          alt="stats"
                          className="w-full rounded-[4px] h-full"
                        />
                      </div>
                    ))}
                  {item?.card_link?.url ? (
                    <a
                      href={item?.card_link?.url}
                      target="blank"
                      className={`pt-3 pl-3 pr-4 pb-4 flex gap-2 rounded-md border ${
                        activeIndex === index
                          ? "border-purple-1500 bg-[#fff]"
                          : "border-[#f0f0f0]"
                      }`}
                      key={index}
                      onMouseEnter={() => handleMouseEnter(index)}
                      onMouseLeave={handleMouseLeave}
                      onClick={() => setActiveIndex(index)}
                    >
                      {item?.icon?.url && (
                        // <GatsbyImage
                        //   image={getImage(
                        //     activeIndex === index
                        //       ? item?.hover_icon
                        //       : item?.icon
                        //   )}
                        //   loading="eager"
                        //   fetchPriority="high"
                        //   width={0}
                        //   height={0}
                        //   alt="stats"
                        //   className="w-full max-w-[29px] h-full"
                        // />
                        <img
                          src={
                            activeIndex === index
                              ? item?.hover_icon?.url
                              : item?.icon?.url
                          }
                          loading="eager"
                          width={0}
                          height={0}
                          alt="stats"
                          className="w-full max-w-[29px] h-full"
                        />
                      )}

                      <div className="flex flex-col gap-1">
                        {item?.title?.text && (
                          <h3
                            className={`font-manrope text-xl font-semibold tracking-tightest_5  ${activeIndex === index ? "text-purple-1100" : "text-gray-2700"}`}
                          >
                            {item?.title?.text}
                          </h3>
                        )}
                        {item?.description?.text && (
                          <p className="text-gray-600 font-inter text-base font-normal tracking-tight_1">
                            {item?.description?.text}
                          </p>
                        )}
                      </div>
                    </a>
                  ) : (
                    <div
                      className={`pt-3 pl-3 pr-4 pb-4 flex gap-2 rounded-md border ${
                        activeIndex === index
                          ? "border-purple-1500 bg-[#fff]"
                          : "border-[#f0f0f0]"
                      }`}
                      key={index}
                      onMouseEnter={() => handleMouseEnter(index)}
                      onMouseLeave={handleMouseLeave}
                      onClick={() => setActiveIndex(index)}
                    >
                      {item?.icon?.url && (
                        // <GatsbyImage
                        //   image={getImage(
                        //     activeIndex === index
                        //       ? item?.hover_icon
                        //       : item?.icon
                        //   )}
                        //   loading="eager"
                        //   fetchPriority="high"
                        //   width={0}
                        //   height={0}
                        //   alt="stats"
                        //   className="w-full max-w-[29px] h-full"
                        // />
                        <img
                          src={
                            activeIndex === index
                              ? item?.hover_icon?.url
                              : item?.icon?.url
                          }
                          loading="eager"
                          width={0}
                          height={0}
                          alt="stats"
                          className="w-full max-w-[29px] h-full"
                        />
                      )}

                      <div className="flex flex-col gap-1">
                        {item?.title?.text && (
                          <h3
                            className={`font-manrope text-xl font-semibold tracking-tightest_5  ${activeIndex === index ? "text-purple-1100" : "text-gray-2700"}`}
                          >
                            {item?.title?.text}
                          </h3>
                        )}
                        {item?.description?.text && (
                          <p className="text-gray-600 font-inter text-base font-normal tracking-tight_1">
                            {item?.description?.text}
                          </p>
                        )}
                      </div>
                    </div>
                  )}

                  <div className="transition-opacity duration-700 ease-in-out relative">
                    {!isDesktop &&
                      isTablet &&
                      activeIndex === index &&
                      (slice?.items[activeIndex]?.video_asset?.url ? (
                        <div className="animate-fade-in-up opacity-0">
                          <SlicesVideoComponent
                            src={slice?.items[activeIndex]?.video_asset?.url}
                            posterImage={posterVideo}
                            className="rounded-[4px]"
                          />
                        </div>
                      ) : (
                        <div
                          className="h-full opacity-0 animate-fade-in-up"
                          data-aos="fade"
                          style={{
                            opacity: activeIndex === index ? 1 : 0,
                            transition: "opacity 0.7s ease-in-out 0.3s",
                          }}
                        >
                          {/* <GatsbyImage
                            image={getImage(
                              slice?.items[activeIndex]?.image_asset
                            )}
                            loading="eager"
                            fetchPriority="high"
                            width={0}
                            height={0}
                            imgClassName="transition-opacity duration-700 ease-in-out"
                            alt="stats"
                            className="w-full rounded-[4px] h-full"
                          /> */}
                          <img
                            src={slice?.items[activeIndex]?.image_asset?.url}
                            loading="eager"
                            width={0}
                            height={0}
                            alt="stats"
                            className="w-full transition-opacity duration-700 ease-in-out rounded-[4px] h-full"
                          />
                        </div>
                      ))}
                  </div>
                </>
              ))}
            </div>
          </div>
          {isDesktop && (
            <div className="flex-[0.5] hidden lg:block">
              {slice?.items[activeIndex]?.video_asset?.url ? (
                <SlicesVideoComponent
                  src={slice?.items[activeIndex]?.video_asset?.url}
                  posterImage={posterVideo}
                  className="rounded-md"
                />
              ) : (
                <div data-aos="fade" className="h-full ">
                  {/* <GatsbyImage
                    image={getImage(slice?.items[activeIndex]?.image_asset)}
                    loading="eager"
                    fetchPriority="high"
                    width={0}
                    height={0}
                    alt="stats"
                    className="w-full rounded-[4px] h-full"
                  /> */}
                  <img
                    src={slice?.items[activeIndex]?.image_asset?.url}
                    loading="eager"
                    fetchPriority="high"
                    width={0}
                    height={0}
                    alt="stats"
                    className="w-full rounded-[4px] h-full"
                  />
                </div>
              )}
            </div>
          )}
        </div>
      </article>
    </section>
  );
};

export default SectionMoore;
