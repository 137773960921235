import { Link } from "gatsby-link";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React from "react";
import { useMediaQuery } from "react-responsive";

const ImageMonet = ({ slice, sliceImageStyle }) => {
  const imageUrl = slice?.primary?.image?.url;
  const mobileAsset = slice?.primary?.mobile_asset?.url;
  const isMobile = useMediaQuery({ maxWidth: 520 });
  return (
    <>
      {slice?.primary?.image_link?.url && imageUrl ? (
        <Link
          to={slice?.primary?.image_link?.url}
          target="_blank"
          className="cursor-pointer"
          aria-label="opens in a new tab"
        >
          <section className="relative px-4 mx-auto sm:px-12 lg:px-5 max-w-7xl">
            <article className="relative lg:mx-11 ">
              <div className={`items-center mx-auto w-fit ${sliceImageStyle}`}>
                {isMobile && mobileAsset ? (
                  // <GatsbyImage
                  //   image={getImage(slice?.primary?.mobile_asset)}
                  //   alt="Image Monet"
                  //   fetchPriority="high"
                  //  loading="lazy"
                  // />
                  <img
                    src={slice?.primary?.mobile_asset?.url}
                    alt="Image Monet"
                    loading="lazy"
                  />
                ) : (
                  // <GatsbyImage
                  //   image={getImage(slice?.primary?.image)}
                  //   alt="Image Monet"
                  //   fetchPriority="high"
                  //   loading="lazy"
                  // />
                  <img
                    src={slice?.primary?.image?.url}
                    alt="Image Monet"
                    loading="lazy"
                  />
                )}
              </div>
            </article>
          </section>
        </Link>
      ) : (
        imageUrl && (
          <section className="relative px-4 mx-auto sm:px-12 lg:px-5 max-w-7xl">
            <article className="relative lg:mx-11 ">
              <div className="items-center mx-auto w-fit">
                {isMobile && mobileAsset ? (
                  // <GatsbyImage
                  //   image={getImage(slice?.primary?.mobile_asset)}
                  //   alt="Image Monet"
                  //   fetchPriority="high"
                  //   loading="lazy"
                  // />
                  <img
                    src={slice?.primary?.mobile_asset?.url}
                    alt="Image Monet"
                    loading="lazy"
                  />
                ) : (
                  // <GatsbyImage
                  //   image={getImage(slice?.primary?.image)}
                  //   alt="Image Monet"
                  //   fetchPriority="high"
                  //   loading="lazy"
                  // />
                  <img
                    src={slice?.primary?.image?.url}
                    alt="Image Monet"
                    loading="lazy"
                  />
                )}
              </div>
            </article>
          </section>
        )
      )}
    </>
  );
};
export default ImageMonet;
