import { Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React from "react";
import { mainTags, hideDate } from "../../../constants";
import { compactObject } from "../../../utilities/CompactObject";
import { formatDateYearShort } from "../../../utilities/FormatBlogDate";
import { _slugify, sanatizeSearchText } from "../../../utilities/helpers";
import Tag from "./Tag";

function removeExpressions(htmlString) {
  // Define the regular expression pattern to match {{some_text}} and {{some_text_2}}
  var regex = /{{[^{}]+}}/g;

  // Replace all matches with an empty string
  return htmlString?.replace(regex, "");
}

/**
 * @summary A single blog card
 * @param {Object} {blog} a blog object from prismic
 * @returns JSX.Element
 */

const BlogCard = ({
  blog,
  location,
  searchText,
  allowSubDetails = true,
  containerClasses,
  DisplayLogo = true,
  imageClasses,
}) => {
  const regex = new RegExp(sanatizeSearchText(searchText), "iu");
  const params = new URLSearchParams(location?.search);
  const _tag = params.get("tag");
  const content = blog?.node?.data?.title?.text?.replace(
    regex,
    `<span style="background:yellow">${blog?.node?.data?.title?.text?.match(
      regex
    )}</span>`
  );

  const {
    image,
    sub_image,
    content: description,
    read_time,
  } = blog.node?.data || {};
  const publish_date =
    blog?.node?.data?.custom_publish_date || blog?.node?.first_publication_date;

  return (
    <div
      className={`flex flex-col bg-white rounded-[18px] border border-solid border-gray-1600 h-[100%] card-3d ${containerClasses}`}
    >
      <div className="relative">
        <Link
          to={`/blog/${blog?.node?.uid}/`}
          className={`relative w-full h-full`}
          state={{ prevPath: location?.pathname }}
        >
          {/* <GatsbyImage
            image={getImage(image)}
            alt={`sampleImage_${blog?.node?.uid}`}
            className={`aspect-[16/9] object-cover w-full rounded-t-[18px] h-full  ${imageClasses}`}
            loading="lazy"
            fetchPriority="high"
          /> */}
          <img
            src={image?.url}
            alt={`sampleImage_${blog?.node?.uid}`}
            className={`aspect-[16/9] object-cover w-full rounded-t-[18px] h-full  ${imageClasses}`}
            loading="lazy"
          />
        </Link>
      </div>
      <div className="flex flex-col items-start flex-1 p-5">
        <div className="flex flex-wrap items-center w-full gap-1 lg:gap-2.5">
          {blog?.node?.tags?.map((tag, i) => {
            const slug = _slugify(tag);
            const query = compactObject({
              tag: _tag ? _tag : mainTags.includes(tag) ? slug : "",
              sub: mainTags.includes(tag) ? "" : slug,
            });
            return (
              <Link
                key={`blog_tag_${i}`}
                state={{ prevPath: location?.pathname }}
                to={`/blog?${new URLSearchParams(query).toString()}`}
                className="mb-[14px]"
              >
                <Tag text={tag} />
              </Link>
            );
          })}
        </div>

        {DisplayLogo && (
          <GatsbyImage
            image={getImage(sub_image)}
            alt={`sampleImage_${blog?.node?.uid}`}
            className="max-w-[80px] min-h-[42px] max-h-[42px]"
            imgClassName="!object-scale-down"
            loading="lazy"
            fetchPriority="high"
          />
          // <img
          //   src={sub_image?.url}
          //   alt={`sampleImage_${blog?.node?.uid}`}
          //   className="max-w-[80px] min-h-[42px] !object-scale-down max-h-[42px]"
          //   loading="lazy"
          // />
        )}

        <div className="flex flex-col justify-between flex-1 w-full">
          <Link
            state={{ prevPath: location?.pathname }}
            to={`/blog/${blog?.node?.uid}/`}
            className=" hover:text-purple-500"
          >
            <h5
              className="line-clamp-2 text-xl font-semibold font-manrope tracking-tightest mb-[14px] text-purple-1100"
              dangerouslySetInnerHTML={{
                __html: content,
              }}
            />
          </Link>

          <p className="text-sm tracking-[-0.7px] line-clamp-2 text-gray-600 block lg:hidden mb-[14px]">
            {removeExpressions(description?.text)}
          </p>

          <div className="flex items-center justify-between w-full mt-1.5">
            {allowSubDetails && (
              <div className="flex items-center text-gray-1200 font-semibold justify-between flex-1 font-manrope uppercase tracking-[1.2px]">
                {publish_date && (
                  <p className="text-xs">
                    {hideDate.includes(`/blog/${blog?.node?.uid}/`)
                      ? null
                      : publish_date
                        ? formatDateYearShort(publish_date)
                        : "--"}
                  </p>
                )}

                <section className="flex items-center gap-1">
                  <span className="size-[16px]">
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      role="presentation"
                    >
                      <path
                        d="M8 1.00012C4.13438 1.00012 1 4.1345 1 8.00012C1 11.8657 4.13438 15.0001 8 15.0001C11.8656 15.0001 15 11.8657 15 8.00012C15 4.1345 11.8656 1.00012 8 1.00012ZM8 13.8126C4.79063 13.8126 2.1875 11.2095 2.1875 8.00012C2.1875 4.79075 4.79063 2.18762 8 2.18762C11.2094 2.18762 13.8125 4.79075 13.8125 8.00012C13.8125 11.2095 11.2094 13.8126 8 13.8126Z"
                        fill="#8C8C8C"
                      />
                      <path
                        d="M10.7297 9.97823L8.50156 8.3673V4.50012C8.50156 4.43137 8.44531 4.37512 8.37656 4.37512H7.625C7.55625 4.37512 7.5 4.43137 7.5 4.50012V8.80323C7.5 8.84386 7.51875 8.88136 7.55156 8.9048L10.1359 10.7892C10.1922 10.8298 10.2703 10.8173 10.3109 10.7626L10.7578 10.1532C10.7984 10.0954 10.7859 10.0173 10.7297 9.97823Z"
                        fill="#8C8C8C"
                      />
                    </svg>
                  </span>
                  {read_time ? (
                    <p className="text-xs tracking-[1.2px]">{read_time} M</p>
                  ) : (
                    <p className="text-xs tracking-[1.2px]">5 M</p>
                  )}
                </section>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogCard;
