import React from "react";
import "swiper/css";
import "../../../styles/page/annotation_pages.scss";
import CustomButton from "../../CustomButton";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const NextArrow = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    role="presentation"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.67251 9.66797L12.9575 9.66797L6.80161 15.0117C6.70318 15.0979 6.76294 15.2578 6.89302 15.2578L8.44868 15.2578C8.51724 15.2578 8.58228 15.2332 8.63325 15.1893L15.276 9.42539C15.3369 9.37265 15.3857 9.30745 15.4191 9.23421C15.4525 9.16097 15.4698 9.08139 15.4698 9.00088C15.4698 8.92036 15.4525 8.84079 15.4191 8.76755C15.3857 8.6943 15.3369 8.62911 15.276 8.57637L8.59458 2.77734C8.56821 2.75449 8.53657 2.74219 8.50318 2.74219L6.89478 2.74219C6.7647 2.74219 6.70493 2.90391 6.80337 2.98828L12.9575 8.33203L2.67251 8.33203C2.59517 8.33203 2.53189 8.39531 2.53189 8.47266L2.53189 9.52734C2.53189 9.60469 2.59517 9.66797 2.67251 9.66797Z"
      fill="#434343"
    />
  </svg>
);

const StatMonetCards = ({ data }) => {
  return (
    <div className="flex gap-3 pr-12 overflow-x-auto lg:pr-0 lg:w-auto sm:gap-6 scroll-smooth no-scrollbar tracking-tight_1 ">
      {data?.map(({ text, logo, cta_title, cta_link }, index) => (
        <div
          key={index}
          className="customer_card_section card_rotating_border p-5 sm:px-5 rounded-2xl sm:rounded-2.5xl sm:pt-14 sm:pb-9 min-h-[200px] sm:min-h-auto min-w-[270px] sm:min-w-[358px] max-w-[358px] gap-5 sm:gap-10 justify-between flex flex-col border border-solid border-gray-1600 ease-in-out duration-500"
        >
          <div className="flex flex-col justify-center gap-7.5">
            {logo?.url && (
              <div className="self-end">
                {/* <GatsbyImage
                  image={getImage(logo)}
                  // src={logo?.url}
                  loading="eager"
                  fetchPriority="high"
                  alt={logo?.alt || "Avatar"}
                  className="w-auto object-scale-down h-auto max-w-[100px]"
                /> */}
                <img
                  src={logo?.url}
                  loading="eager"
                  alt={logo?.alt || "Avatar"}
                  className="w-auto object-scale-down h-auto max-w-[100px]"
                />
              </div>
            )}

            <h1
              dangerouslySetInnerHTML={{ __html: text?.html }}
              className={`text-gray-600 sm:leading-tight font-manrope customorCardHeading ${
                index == 0 ? "max-w-[250px]" : ""
              } text-base sm:text-xl min-h-[88px] sm:min-h-auto -tracking-[1px] font-semibold`}
            />
          </div>

          <CustomButton
            text={cta_title?.text}
            link={cta_link?.url}
            icon={<NextArrow />}
            buttonClass="bg-gray-1500 hover:text-gray-2700 hover:bg-gray-1600 text-gray-2500"
            direction="right"
          />
        </div>
      ))}
    </div>
  );
};

export default StatMonetCards;
