import React, { useContext } from "react";
import TestimonialCardsMarquee from "../../Pieces/TestimonialCardsMarquee";
import HeadingSection from "../../Pieces/HeadingSection";
import ModalContext from "../../../Contexts/openModal";

const TestimonialRaphael = ({ slice }) => {
  const { toggleModal } = useContext(ModalContext);
  const emptyHeading = !!(
    slice?.primary?.caption_title?.text ||
    slice?.primary?.show_cta ||
    slice?.primary?.description?.text ||
    slice?.primary?.title?.text
  );
  return (
    <div className="py-10 lg:py-20">
      <section className="relative px-4 mx-auto sm:px-12 lg:px-5 max-w-7xl">
        <article className="lg:mx-11">
          {(slice?.primary?.show_caption_title || slice?.primary?.title?.text)  && (
            <HeadingSection
              headingStyle="text-2.5xl sm:text-3.25xl text-purple-1100"
              data={slice?.primary}
              toggleModal={toggleModal}
            />
          )}
        </article>
      </section>
      <div className="flex flex-col gap-[18px]">
        <TestimonialCardsMarquee
          data={slice?.items}
          cardColor={slice?.primary?.card_color}
        />
        {slice?.primary?.card_layout === "2 rows" && (
          <TestimonialCardsMarquee
            data={slice?.items}
            cardColor={slice?.primary?.card_color}
            leftToRight={true}
          />
        )}
      </div>
    </div>
  );
};

export default TestimonialRaphael;
