import { navigate } from "@reach/router";
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { useMediaQuery } from "react-responsive";
import { linkResolver } from "../../linkResolver";
import { BlogBackIcon } from "../assets/icons";
import Catalog from "../components/Catalog";
import DemoModal from "../components/DemoModal";
import BlogPopUp from "../components/blog_Pages/BlogPopUp";
import PreviousNextBlog from "../components/blog_Pages/PreviousNextBlog";
import CustomerVerticalCard from "../components/customerLp/CustomerVerticalCard";
import InfoCard from "../components/customerLp/InfoCard";
import { customFaqData } from "../components/customerLp/customFaq";
import Layout from "../components/layout";
import AnnotateFooter from "../components/newAnnotate/AnnotateFooter";
import FAQ from "../components/pricing_page/FAQ";
import { useHeadsObserver } from "../hooks/useHeadsObserver";
import Footer from "../partials/Footer";
import Header from "../partials/Header";
import SocialMediaHandles from "../partials/SocialMediaHandles";
import "../styles/page/blog_page.scss";
import ModalContext, { ModalProvider } from "../Contexts/openModal";
import BlogPopUpModal from "../components/V3/Blog/BlogPopUpModal";
import BlogContent from "../components/V3/BlogDetail/BlogContent";
import ReadingProgress from "../components/V3/BlogDetail/ReadingProgress";
import { blogPopUpData } from "../components/V3/BlogDetail/common";
import { handleCTA } from "../components/V3/helper";
import { formatDateYear } from "../utilities/FormatBlogDate";
import {
  generateSeoSchema,
  pickSlice,
  removeExpressions,
} from "../utilities/helpers";
import { addAltAndLazyToImg } from "../utilities/BlogAltText";

const BlogDetailPage = (props) => {
  const [showBackToTop, setShowBackToTop] = useState(false);
  const { data, location } = props;

  const target = useRef(null);
  const author = data?.prismicBlog?.data?.author?.document;
  const encord_other_products = data?.prismicCaseStudies?.data?.body[1];
  if (!data) return null;
  const blog = data?.prismicBlog;

  // Blog Filters
  const allBlogExceptArticals = data?.allPrismicBlog?.edges.filter(
    (_blog) =>
      _blog.node.uid !== data?.data?.prismicBlog?.uid &&
      !_blog.node.tags.includes("Customers")
  );
  const currentBlogIndex = allBlogExceptArticals?.findIndex(
    (_blog) => _blog?.node?.uid === blog?.uid
  );
  const totalBlogs = allBlogExceptArticals?.length - 1;
  const prevBlogIndex =
    currentBlogIndex === 0 ? totalBlogs : currentBlogIndex - 1;
  const nextBlogIndex =
    currentBlogIndex === totalBlogs ? 0 : currentBlogIndex + 1;
  const prevBlog = allBlogExceptArticals[prevBlogIndex];
  const nextBlog = allBlogExceptArticals[nextBlogIndex];

  // Customer Artical Filters
  const customerArticals = data?.allPrismicBlog?.edges.filter(
    (_blog) =>
      _blog.node.uid !== data?.data?.prismicBlog?.uid &&
      _blog.node.tags.includes("Customers")
  );
  const currentCustomerArticalIndex = customerArticals.findIndex(
    (_blog) =>
      _blog?.node?.uid === blog?.uid && _blog.node.tags.includes("Customers")
  );
  const totalCustomerArticals = customerArticals.length - 1;
  const prevCustomerArticalIndex =
    currentCustomerArticalIndex === 0
      ? totalCustomerArticals
      : currentCustomerArticalIndex - 1;
  const nextCustomerArticalIndex =
    currentCustomerArticalIndex === totalCustomerArticals
      ? 0
      : currentCustomerArticalIndex + 1;
  const prevArtical = customerArticals[prevCustomerArticalIndex];
  const nextArtical = customerArticals[nextCustomerArticalIndex];

  //filter
  const _data = data.prismicBlog?.data;
  const [faq_schema] = pickSlice(_data, "faq_schema");
  // Home Page Quiz Slice
  const [...homepage_quiz] = pickSlice(_data, "homepage_quiz");
  const [quizResultsData] = pickSlice(_data, "quiz_result");
  const [quizFormsData] = pickSlice(_data, "homepage_quizform");
  const [overviewVersionData] = pickSlice(_data, "overview_version");
  const [CompareChartVersionData] = pickSlice(_data, "compare_chart_version");
  const [...detailed_product_version_data] = pickSlice(
    _data,
    "detailed_product_version"
  );
  const [related_blogs] = pickSlice(_data, "related_blogs");
  const [...customer_key_points] = pickSlice(_data, "customer_key_points");
  const table_v3 = pickSlice(_data, "table_v3");
  const [showPopup, setShowPopup] = useState(false);
  const [_closedBlogPopup, _setClosedBlogPopup] = useState("[]");
  const [scrollY, setScrollY] = useState(0);
  // const [isOpen, setIsOpen] = useState(false);
  const { activeId } = useHeadsObserver();
  const { isOpen, toggleModal, email, setEmail } = useContext(ModalContext);
  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Control Button Scroll
  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const totalHeight = document.documentElement.scrollHeight;
      const viewportHeight = window.innerHeight;
      const scrolledPercentage =
        (scrollPosition / (totalHeight - viewportHeight)) * 100;
      if (scrolledPercentage >= 25) {
        setShowBackToTop(true);
      } else {
        setShowBackToTop(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleClose = () => {
    const _closedBlogPopup = localStorage.getItem("closedBlogPopup") || "[]";
    const closedBlogPopup = JSON.parse(_closedBlogPopup);
    if (!closedBlogPopup.includes(location.pathname)) {
      localStorage.setItem(
        "closedBlogPopup",
        JSON.stringify([...closedBlogPopup, location?.pathname])
      );
    }
    setShowPopup(false);
  };
  let __html = handleCTA(blog?.data?.content?.html, blog, table_v3);

  let modifiedHtml = addAltAndLazyToImg(
    __html
      .replaceAll(/\{\{(sub|sup)_start\}\}/g, (_, type) => `<${type}>`)
      .replaceAll(/\{\{(sub|sup)_end\}\}/g, (_, type) => `</${type}>`)
  );

  const catalog = [
    ...blog?.data?.content?.html?.matchAll(/<\s*h2[^>]*>(.*?)<\/h2>/g),
  ].map((v, index) => {
    const [elemt] = v;
    const headerID = index + 1;
    const _elem = elemt.split(">");
    _elem[0] = `${_elem[0]} id="h${headerID}" style="padding-top:20px; margin-top: 0px"`;
    const replacedElem = elemt.replace(/<br\s*\/?>/g, ""); // Remove <br> tags
    modifiedHtml = modifiedHtml.replace(elemt, `${_elem.join(">")}`);
    return `<li style="font-weight: 600" class="catalog-content ${
      activeId === "h" + headerID && "catalog-content-active"
    } md:py-1.5  text-gray-1200 hover:text-purple-1500  flex items-center"><a href="#h${
      index + 1
    }">${replacedElem}</a></li>`;
  });

  const catalogContent = [
    ...blog?.data?.content?.html?.matchAll(/<\s*h2[^>]*>(.*?)<\/h2>/g),
  ].map((v, index) => {
    const [elemt, content] = v;
    const cleanedContent = content.replace(/<br\s*\/?>/gi, "");

    return { text: cleanedContent, id: `h${index + 1}` };
  });
  const publish_date =
    blog?.data?.custom_publish_date || blog?.first_publication_date;
  const pathname = location?.pathname;
  let blogs;

  const isBlog = pathname.startsWith("/blog");
  const isCustomers = pathname.startsWith("/customers");

  const handleLink = () => {
    return isBlog ? "/blog/" : "/customers/";
  };

  if (isBlog) {
    blogs = [prevBlog, nextBlog];
  } else {
    blogs = [prevArtical, nextArtical];
  }

  return (
    <Layout overflow={false} loadLayoutWithScriptsOnly={true}>
      <Helmet>
        {faq_schema?.items?.length && (
          <script type="application/ld+json">
            {`
            {
              "@context": "https://schema.org",
              "@type": "FAQPage",
              "mainEntity": [
                ${faq_schema?.items
                  ?.map((item) => {
                    const { question, answer } = item;
                    return `{
                      "@type": "Question",
                      "name": "${question?.text}",
                      "acceptedAnswer": {
                        "@type": "Answer",
                        "text": "${answer?.text}"
                      }
                    }`;
                  })
                  .join(",")}
              ]
            }
          `}
          </script>
        )}
        <script type="application/ld+json">
          {generateSeoSchema({ location, blog, author })}
        </script>
      </Helmet>

      <div
        className="relative flex flex-col min-h-screen overflow-clip"
        id="blog_page_top"
      >
        {showBackToTop && (
          <button
            onClick={() => {
              const topElement = document.getElementById("blog_page_top");
              topElement?.scrollIntoView({ behavior: "smooth" });
            }}
            className="fixed z-50 flex flex-col items-center gap-2 transition duration-300 bottom-5 right-5"
          >
            <div className="p-3.5 bg-purple-1500 text-white rounded-full shadow-lg hover:bg-purple-1800 transition duration-300">
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                role="presentation"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="-rotate-90"
              >
                <path
                  d="M2.67251 9.66797L12.9575 9.66797L6.80161 15.0117C6.70318 15.0979 6.76294 15.2578 6.89302 15.2578L8.44868 15.2578C8.51724 15.2578 8.58228 15.2332 8.63325 15.1893L15.276 9.42539C15.3369 9.37265 15.3857 9.30745 15.4191 9.23421C15.4525 9.16097 15.4698 9.08139 15.4698 9.00088C15.4698 8.92036 15.4525 8.84079 15.4191 8.76755C15.3857 8.6943 15.3369 8.62911 15.276 8.57637L8.59458 2.77734C8.56821 2.75449 8.53657 2.74219 8.50318 2.74219L6.89478 2.74219C6.7647 2.74219 6.70493 2.90391 6.80337 2.98828L12.9575 8.33203L2.67251 8.33203C2.59517 8.33203 2.53189 8.39531 2.53189 8.47266L2.53189 9.52734C2.53189 9.60469 2.59517 9.66797 2.67251 9.66797Z"
                  fill="#FFFFFF"
                />
              </svg>
            </div>
            <p className="tracking-[-0.9px] font-manrope text-purple-1100 text-lg leading-7 font-semibold hidden lg:block">
              Back to top
            </p>
          </button>
        )}
        <article className="flex-grow">
          {showPopup && (
            <div className="fixed inset-0 z-20 bg-[#0000004d] hidden lg:flex justify-center">
              <article className="absolute mx-auto transform -translate-x-1/2 -translate-y-[45%] top-1/2 right-0 sm:left-1/2 mb-14 max-w-7xl sm:mb-20">
                <BlogPopUp
                  closeFunction={handleClose}
                  blogPopUpData={blogPopUpData}
                />
              </article>
            </div>
          )}
          <div
            className={`flex flex-col header_position sticky top-0 z-20 ${scrollY < 10 ? "bg-transparent" : "custom_box_shadow"}`}
          >
            <Header
              location={location}
              theme={scrollY < 10 ? "transparent" : "default"}
            />
            <ReadingProgress target={target} />
            <div
              className={`flex sm:flex-row flex-col items-center justify-between px-4 sm:px-12 z-10 lg:hidden top-[76px] sm:pt-0 sm:top-[75px] !shadow-none  max-w-6xl`}
            >
              <div
                role="button"
                tabIndex={0}
                onClick={() => navigate(handleLink())}
                className={`${
                  !catalogContent.length && "mb-4"
                } text-base font-semibold items-center justify-between leading-6 tracking-tight_1 flex w-full gap-1.5 ${scrollY < 10 ? "text-gray-1400 " : "text-gray-600"}  cursor-pointer font-manrope`}
              >
                <div className="flex items-center gap-2">
                  <BlogBackIcon
                    fillColor={scrollY < 10 ? "white " : "#595959"}
                  />
                  Back to {isBlog ? "Blogs" : "Case Studies"}
                </div>

                <div className="lg:w-auto w-full max-w-fit sm:max-w-[341px]">
                  {!!catalogContent.length && (
                    <Catalog catalogContent={catalogContent} />
                  )}
                </div>
              </div>
            </div>
          </div>

          <article
            className={`${customer_key_points?.length === 1 ? "customer_section_gradient pb-10 sm:pb-0" : "blog_section_gradient  pb-10 sm:pb-18"}  pt-[242px] sm:pt-[198px] lg:pt-[110px]  -mt-[244px] sm:-mt-52 lg:-mt-28 z-0`}
          >
            <section className="max-w-6xl mx-auto mt-4">
              <section
                className={`mt-9 flex flex-col ${isBlog ? "flex-col lg:flex-row lg:items-center" : "flex-col"}  gap-[54px] mx-4 sm:mx-12 lg:mx-6 xl:mx-0`}
              >
                <section
                  className={`flex flex-col  ${isBlog ? "max-w-[526px] gap-8" : "gap-8 md:gap-5"}`}
                >
                  <h6
                    className={`text-base font-medium uppercase tracking-[1.2px] sm:tracking-widest_1 text-purple-350 ${pathname?.startsWith("/blog/") ? "hidden" : "block sm:text-base text-xs "}  lg:block`}
                  >
                    {isBlog ? "Encord Blog" : "Case Studies"}
                  </h6>
                  <h1 className="!text-2.5xl sm:!text-[36px] m-0 tracking-[-1.6px] sm:tracking-[-1.8px] font-semibold sm:font-bold text-white font-manrope">
                    {blog.data.title.text}
                  </h1>

                  <section className="flex text-gray-2200/75 tracking-[-0.8px]">
                    <div className="flex flex-col flex-wrap xs:items-center xs:flex-row">
                      {formatDateYear(publish_date)}
                    </div>
                    {blog?.data?.read_time ? (
                      <>
                        <span className=" mx-2.5">|</span>
                        <div className="flex items-center gap-1.5">
                          <svg
                            width="16"
                            height="16"
                            role="presentation"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M8 1.00012C4.13438 1.00012 1 4.1345 1 8.00012C1 11.8657 4.13438 15.0001 8 15.0001C11.8656 15.0001 15 11.8657 15 8.00012C15 4.1345 11.8656 1.00012 8 1.00012ZM8 13.8126C4.79063 13.8126 2.1875 11.2095 2.1875 8.00012C2.1875 4.79075 4.79063 2.18762 8 2.18762C11.2094 2.18762 13.8125 4.79075 13.8125 8.00012C13.8125 11.2095 11.2094 13.8126 8 13.8126Z"
                              fill="#BFBFBF"
                            />
                            <path
                              d="M8 1.00012C4.13438 1.00012 1 4.1345 1 8.00012C1 11.8657 4.13438 15.0001 8 15.0001C11.8656 15.0001 15 11.8657 15 8.00012C15 4.1345 11.8656 1.00012 8 1.00012ZM8 13.8126C4.79063 13.8126 2.1875 11.2095 2.1875 8.00012C2.1875 4.79075 4.79063 2.18762 8 2.18762C11.2094 2.18762 13.8125 4.79075 13.8125 8.00012C13.8125 11.2095 11.2094 13.8126 8 13.8126Z"
                              fill="#1D0642"
                              fill-opacity="0.25"
                            />
                            <path
                              d="M10.7297 9.97823L8.50156 8.3673V4.50012C8.50156 4.43137 8.44531 4.37512 8.37656 4.37512H7.625C7.55625 4.37512 7.5 4.43137 7.5 4.50012V8.80323C7.5 8.84386 7.51875 8.88136 7.55156 8.9048L10.1359 10.7892C10.1922 10.8298 10.2703 10.8173 10.3109 10.7626L10.7578 10.1532C10.7984 10.0954 10.7859 10.0173 10.7297 9.97823Z"
                              fill="#BFBFBF"
                            />
                            <path
                              d="M10.7297 9.97823L8.50156 8.3673V4.50012C8.50156 4.43137 8.44531 4.37512 8.37656 4.37512H7.625C7.55625 4.37512 7.5 4.43137 7.5 4.50012V8.80323C7.5 8.84386 7.51875 8.88136 7.55156 8.9048L10.1359 10.7892C10.1922 10.8298 10.2703 10.8173 10.3109 10.7626L10.7578 10.1532C10.7984 10.0954 10.7859 10.0173 10.7297 9.97823Z"
                              fill="#1D0642"
                              fill-opacity="0.25"
                            />
                          </svg>

                          <span>{blog?.data?.read_time} mins</span>
                        </div>
                      </>
                    ) : (
                      <>
                        <span className="hidden mx-2.5 sm:block">|</span>
                        <div className="flex items-center gap-1.5">
                          <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            role="presentation"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M8 1.00012C4.13438 1.00012 1 4.1345 1 8.00012C1 11.8657 4.13438 15.0001 8 15.0001C11.8656 15.0001 15 11.8657 15 8.00012C15 4.1345 11.8656 1.00012 8 1.00012ZM8 13.8126C4.79063 13.8126 2.1875 11.2095 2.1875 8.00012C2.1875 4.79075 4.79063 2.18762 8 2.18762C11.2094 2.18762 13.8125 4.79075 13.8125 8.00012C13.8125 11.2095 11.2094 13.8126 8 13.8126Z"
                              fill="#BFBFBF"
                            />
                            <path
                              d="M8 1.00012C4.13438 1.00012 1 4.1345 1 8.00012C1 11.8657 4.13438 15.0001 8 15.0001C11.8656 15.0001 15 11.8657 15 8.00012C15 4.1345 11.8656 1.00012 8 1.00012ZM8 13.8126C4.79063 13.8126 2.1875 11.2095 2.1875 8.00012C2.1875 4.79075 4.79063 2.18762 8 2.18762C11.2094 2.18762 13.8125 4.79075 13.8125 8.00012C13.8125 11.2095 11.2094 13.8126 8 13.8126Z"
                              fill="#1D0642"
                              fill-opacity="0.25"
                            />
                            <path
                              d="M10.7297 9.97823L8.50156 8.3673V4.50012C8.50156 4.43137 8.44531 4.37512 8.37656 4.37512H7.625C7.55625 4.37512 7.5 4.43137 7.5 4.50012V8.80323C7.5 8.84386 7.51875 8.88136 7.55156 8.9048L10.1359 10.7892C10.1922 10.8298 10.2703 10.8173 10.3109 10.7626L10.7578 10.1532C10.7984 10.0954 10.7859 10.0173 10.7297 9.97823Z"
                              fill="#BFBFBF"
                            />
                            <path
                              d="M10.7297 9.97823L8.50156 8.3673V4.50012C8.50156 4.43137 8.44531 4.37512 8.37656 4.37512H7.625C7.55625 4.37512 7.5 4.43137 7.5 4.50012V8.80323C7.5 8.84386 7.51875 8.88136 7.55156 8.9048L10.1359 10.7892C10.1922 10.8298 10.2703 10.8173 10.3109 10.7626L10.7578 10.1532C10.7984 10.0954 10.7859 10.0173 10.7297 9.97823Z"
                              fill="#1D0642"
                              fill-opacity="0.25"
                            />
                          </svg>
                          <span>5 mins</span>
                        </div>
                      </>
                    )}
                  </section>
                  <SocialMediaHandles containerClass="!justify-start !flex" />
                </section>

                {isBlog && (
                  <section className="rounded-[10px]">
                    {(blog?.data?.external_video?.html &&
                      blog?.data?.external_video?.html !== "<p></p>") ||
                    blog?.uid === "webinar-fine-tune-segment-anything-model" ? (
                      <div
                        dangerouslySetInnerHTML={{
                          __html:
                            blog?.uid ===
                            "webinar-fine-tune-segment-anything-model"
                              ? `<iframe width="560" height="315" src="https://www.youtube.com/embed/EUjhmnZweYM?si=GHn74vas6v7uzdXx" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>`
                              : blog?.data?.external_video?.html,
                        }}
                        className="pt-5"
                      />
                    ) : (
                      // <GatsbyImage
                      //   loading="eager"
                      //   fetchPriority="high"
                      //   image={getImage(blog.data?.image)}
                      //   alt="blog image"
                      //   className="aspect-video pt-5 pb-12 rounded-[10px]"
                      //   objectFit="cover"
                      // />
                      <img
                        loading="eager"
                        src={blog.data?.image?.url}
                        alt="blog image"
                        className="aspect-video pt-5 object-cover pb-12 rounded-[10px]"
                      />
                    )}
                  </section>
                )}
                {isCustomers && blog?.data?.customer_detail_hero_icon?.url && (
                  <section className=" -order-1 detail_hero_section_gradient w-full sm:h-[290px] h-[184px] rounded-[10px] flex justify-center items-center">
                    <img
                      src={blog?.data?.customer_detail_hero_icon?.url}
                      loading="eager"
                      alt="Detail Page Image"
                      className={`sm:max-w-[241px] max-w-[193px] sm:min-h-[55px] sm:max-h-[55px] max-h-[44px] rounded-[10px] ${blog?.data?.customer_detail_hero_icon?.url ? "" : "filter brightness-0 invert"}`}
                      imgClassName="!object-scale-down"
                      placeholder="none"
                    />
                  </section>
                )}
                <section className="flex flex-col gap-5">
                  {customer_key_points?.length > 0 &&
                    customer_key_points.map((slice, sliceIndex) => (
                      <section
                        key={sliceIndex}
                        className="flex flex-col max-w-6xl gap-5 mx-auto sm:flex-row"
                      >
                        {slice?.items?.map((point, index) => (
                          <InfoCard
                            key={`${sliceIndex}-${index}`}
                            icon={point?.card_icon?.url}
                            title={point?.card_title?.text}
                            text={point?.card_description?.text}
                          />
                        ))}
                      </section>
                    ))}
                </section>
              </section>
            </section>
          </article>

          <section className="max-w-6xl px-4 pt-5 pb-5 mx-auto sm:px-12 lg:px-4 xl:px-0 sm:pt-10 md:px-10 sm:pb-10">
            <div ref={target}>
              <BlogContent
                quizResult={quizResultsData}
                quizForm={quizFormsData}
                homepage_quiz={homepage_quiz}
                blog={blog}
                location={location}
                html={modifiedHtml}
                catalogContent={catalogContent}
                handleLink={handleLink}
                overviewVersion={overviewVersionData}
                compareChartVersion={CompareChartVersionData}
                detailedProductVersionData={detailed_product_version_data}
              />
            </div>
          </section>

          {((faq_schema?.items && !pathname.startsWith("/customers/")) ||
            (customFaqData?.items && pathname.startsWith("/customers/"))) && (
            <section className="bg-purple-1100">
              <div className="max-w-6xl px-4 py-10 mx-auto sm:py-20 xl:px-0 lg:px-6 sm:px-12">
                <FAQ
                  heading={`Frequently asked questions`}
                  items={
                    pathname.startsWith("/customers/")
                      ? customFaqData?.items
                      : faq_schema?.items
                  }
                  darkClass="faqDarkTheme"
                />
              </div>
            </section>
          )}

          <section className="max-w-6xl py-5 mx-auto sm:py-10">
            <PreviousNextBlog {...{ blogs, location }} />
          </section>
          {/* {pathname.startsWith("/blog/") && (
            <div className="flex-col max-w-6xl px-4 pt-5 pb-1 mx-auto lg:pb-0 lg:pt-0 related_blog_section_gradient md:flex md:justify-between sm:px-12 lg:px-6 xl:px-0">
              {!ismobile ? (
                <div className="flex justify-between">
                  <h6 className="text-left text-[24px] font-manrope tracking-[-1.8px] text-gray-2700  font-semibold">
                    Related blogs
                  </h6>
                  <div
                    onClick={() => {
                      navigate("https://encord.com/blog/");
                    }}
                    className={`
                   text-base font-semibold tracking-[-0.4px] flex items-center gap-1.5 cursor-pointer font-manrope`}
                  >
                    View all
                    <RightIcon />
                  </div>
                </div>
              ) : (
                <h6 className="text-left text-[36px] font-manrope -tracking-[1.8px] text-purple-1100 font-semibold">
                  Related blogs
                </h6>
              )}
              <RelatedBlogSlider
                related_blog={related_blogs}
                allData={data}
                location={location}
              />
            </div>
          )}
          {pathname.startsWith("/customers/") && ( */}
          <section className="z-0 max-w-6xl px-4 pb-10 mx-auto sm:px-12 lg:px-6 xl:px-0 lg:pb-20">
            <CustomerVerticalCard
              data={encord_other_products}
              descriptionClass={`min-h-12`}
            />
          </section>
          {/* )} */}

          <section className="z-0 max-w-6xl px-4 mx-auto sm:px-12 lg:px-6 xl:px-0">
            <AnnotateFooter
              setEmail={setEmail}
              heading=" Software To Help You Turn Your Data Into AI"
              description=" Forget fragmented workflows, annotation tools, and Notebooks for
              building AI applications. Encord Data Engine accelerates every
              step of taking your model into production."
              imageBG={true}
              openModal={toggleModal}
            />
          </section>
        </article>
      </div>
      <BlogPopUpModal />
      <Footer theme={scrollY < 10 ? "transparent" : "newDefault"} />
      <DemoModal
        title={`Get started`}
        isOpen={isOpen}
        email={email}
        closeModal={toggleModal}
        location={location}
      />
    </Layout>
  );
};

const BlogDetail = ({ data, location }) => {
  return (
    <ModalProvider>
      <BlogDetailPage {...{ data, location }} />
    </ModalProvider>
  );
};

export const pageQuery = graphql`
  query BlogByUid($uid: String!) {
    prismicCaseStudies {
      data {
        body {
          ... on PrismicCaseStudiesDataBodyEncordOtherProducts {
            id
            slice_type
            primary {
              section_heading {
                html
                text
              }
            }
            items {
              card_description {
                html
                text
              }
              card_heading {
                html
                text
              }
              card_image {
                alt
                url
              }
              card_label {
                text
              }
              cta_link {
                url
              }
              cta_text {
                text
              }
              icon {
                alt
                url
              }
            }
          }
        }
      }
    }
    prismicBlog(uid: { eq: $uid }) {
      uid
      data {
        author {
          document {
            ... on PrismicAuthor {
              uid
              data {
                full_name {
                  text
                }
                bio {
                  text
                  html
                }
                linkedin_profile_link {
                  url
                }
                twitter_profile_link {
                  url
                }
                avatar {
                  gatsbyImageData(
                    layout: CONSTRAINED
                    placeholder: BLURRED
                    width: 80
                    height: 80
                  )
                  url
                  dimensions {
                    height
                    width
                  }
                }
              }
            }
          }
        }
        read_time
        title {
          text
        }
        keywords {
          text
        }
        meta_heading {
          text
        }
        external_video {
          html
        }
        meta_description {
          text
        }
        content {
          html
          text
        }
        image {
          url
          dimensions {
            height
            width
          }
          gatsbyImageData(
            layout: CONSTRAINED
            placeholder: BLURRED
            width: 906
            height: 638
          )
        }
        sub_image {
          url
        }
        customer_detail_hero_icon {
          url
        }
        body {
          ... on PrismicBlogDataBodyTableV3 {
            id
            slice_type
            items {
              column {
                text
              }
            }
            primary {
              heading {
                text
              }
              key {
                text
              }
              columns
              highlight_first_row
            }
          }
          ... on PrismicBlogDataBodyCustomerKeyPoints {
            slice_type
            items {
              card_description {
                text
              }
              card_title {
                text
              }
              card_icon {
                url
              }
            }
          }
          ... on PrismicBlogDataBodyOtherAuthors {
            id
            slice_type
            items {
              other_authors {
                document {
                  ... on PrismicAuthor {
                    id
                    uid
                    data {
                      avatar {
                        gatsbyImageData(
                          layout: CONSTRAINED
                          placeholder: BLURRED
                          width: 80
                          height: 80
                        )
                        url
                        dimensions {
                          height
                          width
                        }
                      }
                      bio {
                        text
                        html
                      }
                      full_name {
                        text
                      }
                      linkedin_profile_link {
                        url
                      }
                      twitter_profile_link {
                        url
                      }
                    }
                  }
                }
              }
            }
          }
          ... on PrismicBlogDataBodyFaqSchema {
            slice_type
            items {
              answer {
                text
              }
              question {
                text
              }
            }
          }
          ... on PrismicBlogDataBodyRelatedBlogs {
            id
            slice_type
            items {
              related_blog {
                document {
                  ... on PrismicBlog {
                    id
                    uid
                    data {
                      content {
                        text
                        html
                      }
                      title {
                        text
                      }
                      sub_image {
                        gatsbyImageData(
                          width: 368
                          height: 270
                          sizes: "(min-width: 1360px) 368px, (min-width: 1040px) calc(16vw + 154px), (min-width: 640px) calc(50vw - 28px), calc(100vw - 32px)"
                          placeholder: BLURRED
                          layout: CONSTRAINED
                        )
                        alt
                        url
                      }
                      image {
                        gatsbyImageData(
                          width: 368
                          height: 270
                          sizes: "(min-width: 1360px) 368px, (min-width: 1040px) calc(16vw + 154px), (min-width: 640px) calc(50vw - 28px), calc(100vw - 32px)"
                          placeholder: BLURRED
                          layout: CONSTRAINED
                        )
                        url
                        alt
                      }
                      external_video {
                        html
                        text
                      }
                      read_time
                      custom_publish_date
                    }
                    first_publication_date
                    tags
                  }
                }
              }
            }
          }
          ... on PrismicBlogDataBodyTable {
            id
            primary {
              table_title {
                text
              }
            }
            items {
              col1 {
                text
                html
              }
              col2 {
                text
                html
              }
              col3 {
                text
                html
              }
              col4 {
                text
                html
              }
            }
          }
          ... on PrismicBlogDataBodyHomepageQuiz {
            id
            slice_label
            slice_type
            primary {
              theme
              question_title {
                text
                html
              }
            }
            items {
              option {
                html
                text
              }
            }
          }
          ... on PrismicBlogDataBodyHomepageQuizform {
            id
            slice_type
            slice_label
            primary {
              caption_title {
                text
                html
              }
              description {
                html
                text
              }
              title {
                html
                text
              }
            }
          }
          ... on PrismicBlogDataBodyOverviewVersion {
            id
            slice_type
            slice_label
            items {
              overview_bullet_point {
                text
              }
            }
            primary {
              cta_button_link {
                url
              }
              cta_button_text {
                text
              }
              cta_heading {
                html
                text
              }
              cta_image_asset {
                alt
                url
                gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED)
              }
              bottom_spacing
              top_spacing
            }
          }
          ... on PrismicBlogDataBodyQuizResult {
            id
            slice_label
            slice_type
            items {
              image {
                alt
                url
              }
              title {
                text
                html
              }
            }
            primary {
              caption_title {
                text
                html
              }
              title {
                text
                html
              }
              description {
                html
                text
              }
            }
          }
          ... on PrismicBlogDataBodyCompareChartVersion {
            id
            slice_type
            slice_label
            primary {
              cta_heading {
                text
              }
              bottom_spacing
              top_spacing
            }
            items {
              company_image {
                alt
                url
                gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED)
              }
              company_name {
                text
              }
              cta_button_link {
                url
              }
              cta_button_text {
                text
              }
              key_features {
                html
                text
              }
              reviews {
                text
              }
            }
          }
          ... on PrismicBlogDataBodyDetailedProductVersion {
            id
            slice_label
            slice_type
            items {
              feature_cta_link {
                url
              }
              feature_cta_text {
                text
              }
              feature_hover_icon {
                alt
                url
                gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED)
              }
              feature_icon {
                alt
                url
                gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED)
              }
              feature_image_asset {
                alt
                url
                gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED)
              }
              feature_text {
                html
                text
              }
              feature_title {
                text
              }
              feature_video_asset {
                url
              }
            }
            primary {
              product_asset_image {
                alt
                url
                gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED)
              }
              product_asset_video {
                url
              }
              product_description {
                text
              }
              product_icon {
                alt
                url
                gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED)
              }
              product_tag_1 {
                text
              }
              product_tag_2 {
                text
              }
              product_title {
                text
              }
              tab_icon {
                alt
                url
                gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED)
              }
            }
          }
        }
        read_time
        custom_publish_date
      }
      first_publication_date
      last_publication_date
      tags
      _previewable
    }
    allPrismicBlog(
      sort: { first_publication_date: DESC }
      filter: {
        uid: {
          nin: [
            "video-what-the-european-ai-act-means-for-ai-developers"
            "enterprise-ai-in-production-luc-vincent"
            "webinar-workshop-workflows-and-active-learning"
          ]
        }
      }
    ) {
      edges {
        node {
          uid
          data {
            author {
              document {
                ... on PrismicAuthor {
                  uid
                  data {
                    full_name {
                      text
                    }
                  }
                }
              }
            }
            content {
              html
              text
            }
            body {
              ... on PrismicBlogDataBodyRelatedBlogs {
                id
                slice_type
                items {
                  related_blog {
                    document {
                      ... on PrismicBlog {
                        id
                        uid
                        data {
                          content {
                            text
                            html
                          }
                          title {
                            text
                          }
                          sub_image {
                            gatsbyImageData(
                              width: 368
                              height: 270
                              sizes: "(min-width: 1360px) 368px, (min-width: 1040px) calc(16vw + 154px), (min-width: 640px) calc(50vw - 28px), calc(100vw - 32px)"
                              placeholder: BLURRED
                              layout: CONSTRAINED
                            )
                            alt
                            url
                          }
                          image {
                            gatsbyImageData(
                              width: 368
                              height: 270
                              sizes: "(min-width: 1360px) 368px, (min-width: 1040px) calc(16vw + 154px), (min-width: 640px) calc(50vw - 28px), calc(100vw - 32px)"
                              placeholder: BLURRED
                              layout: CONSTRAINED
                            )
                            url
                            alt
                          }
                          external_video {
                            html
                            text
                          }
                          read_time
                          custom_publish_date
                        }
                        first_publication_date
                        tags
                      }
                    }
                  }
                }
              }
              ... on PrismicBlogDataBodyTable {
                id
                primary {
                  table_title {
                    text
                  }
                }
                items {
                  col1 {
                    text
                    html
                  }
                  col2 {
                    text
                    html
                  }
                  col3 {
                    text
                    html
                  }
                  col4 {
                    text
                    html
                  }
                }
              }
              ... on PrismicBlogDataBodyHomepageQuiz {
                id
                slice_label
                slice_type
                primary {
                  theme
                  question_title {
                    html
                    text
                  }
                }
                items {
                  option {
                    text
                    html
                  }
                }
              }
              ... on PrismicBlogDataBodyHomepageQuizform {
                id
                slice_label
                slice_type
                primary {
                  caption_title {
                    html
                    text
                  }
                  description {
                    text
                    html
                  }
                  title {
                    html
                    text
                  }
                }
              }
              ... on PrismicBlogDataBodyQuizResult {
                id
                slice_label
                slice_type
                items {
                  image {
                    alt
                    url
                  }
                  title {
                    html
                    text
                  }
                }
                primary {
                  caption_title {
                    html
                    text
                  }
                  title {
                    html
                    text
                  }
                  description {
                    html
                    text
                  }
                }
              }
            }
            title {
              text
            }
            keywords {
              text
            }
            image {
              gatsbyImageData(
                width: 368
                height: 270
                sizes: "(min-width: 1360px) 368px, (min-width: 1040px) calc(16vw + 154px), (min-width: 640px) calc(50vw - 28px), calc(100vw - 32px)"
                placeholder: BLURRED
                layout: CONSTRAINED
              )
              url
            }
            sub_image {
              gatsbyImageData(
                width: 368
                height: 270
                sizes: "(min-width: 1360px) 368px, (min-width: 1040px) calc(16vw + 154px), (min-width: 640px) calc(50vw - 28px), calc(100vw - 32px)"
                placeholder: BLURRED
                layout: CONSTRAINED
              )
              url
            }
            read_time
          }
          first_publication_date
          last_publication_date
          tags
          _previewable
        }
      }
    }
  }
`;

export default withPrismicPreview(BlogDetail, [
  {
    repositoryName: "encord",
    linkResolver: linkResolver,
  },
]);

export const Head = ({ data, location }) => {
  return (
    <>
      <title>
        {data?.prismicBlog?.data?.meta_heading?.text ||
          `${data?.prismicBlog?.data?.title?.text} | Encord`}
      </title>
      <meta
        name="description"
        content={
          data?.prismicBlog?.data?.meta_description?.text ||
          removeExpressions(data?.prismicBlog?.data?.content?.text)?.slice(
            0,
            144
          )
        }
      />
      <meta
        property="og:title"
        content={data?.prismicBlog?.data?.title?.text}
      />
      <meta property="og:type" content="article" />
      <meta property="og:image" content={data?.prismicBlog?.data?.image?.url} />
      <meta
        property="og:url"
        content={`https://encord.com${location.pathname}`}
      />
      <meta property="og:image:width" content="2167" />
      <meta property="og:image:height" content="1078" />
      <meta
        property="og:description"
        content={removeExpressions(
          data?.prismicBlog?.data?.content?.text
        )?.slice(0, 144)}
      />
      <meta property="og:image:type" content="image/jpeg" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta
        name="twitter:title"
        content={data?.prismicBlog?.data?.title.text}
      />
      <meta
        name="twitter:image"
        content={data?.prismicBlog?.data?.image?.url}
      />
      <meta name="twitter:site" content="@encord_team" />
      <meta name="twitter:creator" content="@encord_team" />
      <meta
        name="twitter:description"
        content={removeExpressions(
          data?.prismicBlog?.data?.content?.text
        )?.slice(0, 144)}
      />
    </>
  );
};
