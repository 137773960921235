import React from "react";
import { mainTags } from "../constants";
import { compactObject } from "../utilities/CompactObject";
import formatDate from "../utilities/FormatBlogDate";
import { _slugify, sanatizeSearchText } from "../utilities/helpers";
import { Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

/**
 * @summary A single blog card
 * @param {Object} {blog} a blog object from prismic
 * @returns JSX.Element
 */
export const ClockIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    role="presentation"
    fill="none"
  >
    <path
      d="M8 1C4.13438 1 1 4.13438 1 8C1 11.8656 4.13438 15 8 15C11.8656 15 15 11.8656 15 8C15 4.13438 11.8656 1 8 1ZM8 13.8125C4.79063 13.8125 2.1875 11.2094 2.1875 8C2.1875 4.79063 4.79063 2.1875 8 2.1875C11.2094 2.1875 13.8125 4.79063 13.8125 8C13.8125 11.2094 11.2094 13.8125 8 13.8125Z"
      fill="#8C8C8C"
    />
    <path
      d="M10.7297 9.97811L8.50156 8.36718V4.5C8.50156 4.43125 8.44531 4.375 8.37656 4.375H7.625C7.55625 4.375 7.5 4.43125 7.5 4.5V8.80311C7.5 8.84374 7.51875 8.88124 7.55156 8.90467L10.1359 10.789C10.1922 10.8297 10.2703 10.8172 10.3109 10.7625L10.7578 10.1531C10.7984 10.0953 10.7859 10.0172 10.7297 9.97811Z"
      fill="#8C8C8C"
    />
  </svg>
);

const CareerBlogCard = ({ blog, location, allowSubDetails = true }) => {
  const params = new URLSearchParams(location.search);
  const _tag = params.get("tag");

  const {
    image,
    content: description,
    read_time,
    title,
    tags,
  } = blog?.company_blogs?.document?.data;
  const uid = blog?.company_blogs?.document?.uid;

  return (
    <div className="flex flex-col border border-gray-1600 bg-white rounded-[18px] max-h-[351px] !min-w-[368px] max-w-[368px]">
      <div className="relative h-56 lg:min-h-56">
        <Link
          to={`/blog/${uid}/`}
          className="relative w-full h-full"
          state={{ prevPath: location?.pathname }}
        >
          <GatsbyImage
          loading="lazy"
          fetchPriority="high"
            image={getImage(image)}
            alt={`sampleImage`}
            className="object-cover w-full h-full rounded-t-[18px]"
          />
          {/* <img
            src={image}
            width={image?.gatsbyImageData?.width}
            height={image?.gatsbyImageData?.height}
            alt={`sampleImage_${blog?.node?.uid}`}
            className="object-cover w-full h-full rounded-t-[18px]"
            loading="lazy"
          /> */}
        </Link>
      </div>
      <div className="flex p-5 ">
        {/* <div className="flex flex-wrap items-center justify-between w-full gap-1">
          {blog?.company_blogs?.document?.tags?.map((tag, i) => {
            const slug = _slugify(tag);
            const query = compactObject({
              tag: _tag ? _tag : mainTags.includes(tag) ? slug : "",
              sub: mainTags.includes(tag) ? "" : slug,
            });
            return (
              <Link
                key={`blog_tag_${i}`}
                state={{ prevPath: location?.pathname }}
                to={`/blog?${new URLSearchParams(query).toString()}`}
                className="text-sm font-semibold tracking-wider text-purple-500 uppercase"
              >
                {tag}
              </Link>
            );
          })}
        </div> */}

        <div className="">
          <Link
            state={{ prevPath: location?.pathname }}
            to={`/blog/${uid}/`}
            className="font-semibold text-xl line-clamp-2 tracking-[-1px] text-purple-1100 font-manrope hover:text-purple-500"
          >
            <span
              className="line-clamp-2"
              dangerouslySetInnerHTML={{
                __html: title?.text,
              }}
            />
          </Link>

          {/* <p className="mt-2 text-sm line-clamp-3 text-gray-1200">
            {description?.text}
          </p> */}

          <div className="flex items-center justify-between w-full mt-5 mb-1 text-xs">
            {allowSubDetails && (
              <div className="flex items-center justify-between flex-1">
                {blog?.company_blogs?.document?.first_publication_date && (
                  <p className="text-gray-1200 font-manrope uppercase -tracking-tightest_5">
                    {/* {blog?.company_blogs?.document?.first_publication_date
                      ? formatDate(
                          blog?.company_blogs?.document?.first_publication_date
                        )
                      : "--"} */}
                  </p>
                )}
                <div className="flex flex-row gap-1 items-center">
                  <ClockIcon />
                  {read_time ? (
                    <p className="text-gray-1200 uppercase text-xs tracking-[1.2px] font-manrope">
                      {read_time} M
                    </p>
                  ) : (
                    <p className="text-gray-1200 text-xs tracking-[1.2px] font-manrope">
                      5 M
                    </p>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CareerBlogCard;
