import React, { useContext } from "react";
import ModalContext from "../../../Contexts/openModal";
import "../../../styles/components/Slices/SectionMonet.scss";
import HeadingSection from "../../Pieces/HeadingSection";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

export const SectionMonet = ({ slice }) => {
  const { toggleModal } = useContext(ModalContext);

  const isTopAligned = slice?.primary?.layout_alignment === "top aligned";
  const imageUrl = slice?.primary?.image?.url;
  return (
    <section className="relative px-4 mx-auto sm:px-12 lg:px-5 max-w-7xl">
      <article className="pt-10 lg:py-20 lg:mx-11">
        <section
          className={`flex flex-col md:flex-row lg:gap-10 ${isTopAligned ? "items-start" : "items-center"}`}
        >
          <div className="md:max-w-[392px]">
            <HeadingSection
              data={slice?.primary}
              toggleModal={toggleModal}
              sectionStyle="lg:!pb-0"
              headingStyle="sm:text-3.25xl !leading-10 -tracking-[0.9px]"
              descriptionStyle="sm:text-lg custom_line_height !-tracking-[0.45px]"
              textAreaStyle="gap-4"
            />
          </div>
          {imageUrl && (
            <div className="items-end hidden bg-image-monet sm:py-10 lg:py-0 sm:-mx-12 lg:-mx-0 lg:pl-16 sm:block">
              {/* <GatsbyImage
                image={getImage(slice?.primary?.image)}
                alt="Monet"
                className="px-12 lg:px-0"
                fetchPriority="high"
                loading="lazy"
              /> */}
              <img
                src={slice?.primary?.image?.url}
                alt="Monet"
                className="px-12 lg:px-0"
                loading="lazy"
              />
            </div>
          )}
          {slice?.primary?.mobile_asset?.url && (
            <div className="items-end block py-10 -mx-4 sm:hidden bg-image-monet">
              {/* <GatsbyImage
                image={getImage(slice?.primary?.mobile_asset)}
                // src={slice?.primary?.mobile_asset?.url}
                alt="mobile_asset"
                className="px-4"
                fetchPriority="high"
                loading="lazy"
              /> */}
              <img
                src={slice?.primary?.mobile_asset?.url}
                alt="mobile_asset"
                className="px-4"
                loading="lazy"
              />
            </div>
          )}
        </section>
      </article>
    </section>
  );
};
