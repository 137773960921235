import React, { useContext, useEffect, useRef, useState } from "react";
import ModalContext from "../../../Contexts/openModal";
import "../../../styles/components/Slices/SectionMonet.scss";
import HeadingSection from "../../Pieces/HeadingSection";
import {
  EncordCheck,
  FeatureBullet,
  V7Check,
  V7Cross,
} from "../../../assets/icons/NavbarIcons/Icons";
import useWindowWidth from "../../../hooks/useBetterMediaQuery";

export const SectionComparison = ({ slice, location }) => {
  const { toggleModal } = useContext(ModalContext);
  const [isSticky, setIsSticky] = useState(false);
  const isDesktop = useWindowWidth(1023);
  const headerRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      if (headerRef.current) {
        const { top } = headerRef.current.getBoundingClientRect();
        setIsSticky(top <= 64);
      }
    };
    window.addEventListener("scroll", handleScroll);
    handleScroll();

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <section
      className="relative px-4 mx-auto sm:px-12 lg:px-5 max-w-7xl"
      id="tableMonet"
    >
      <article className="pt-10 lg:py-20 lg:mx-11">
        <section>
          <HeadingSection
            data={slice?.primary}
            toggleModal={toggleModal}
            sectionStyle="lg:!pb-0"
            headingStyle="sm:text-3.25xl !leading-10 -tracking-[0.9px]"
            descriptionStyle="sm:text-lg custom_line_height !-tracking-[0.45px]"
            textAreaStyle="gap-4"
          />
        </section>
        <section className="lg:mt-10">
          <div
            ref={headerRef}
            className={`sticky lg:hidden top-16 z-10 transition-colors duration-300 ${
              isSticky
                ? "border-b border-b-[#E6E6E6] bg-gray-2800"
                : "bg-transparent"
            }`}
          >
            <div className="flex justify-between py-4 sm:py-6 pl-1.5 md:pl-6 pr-11 md:pr-10">
              <img
                className="h-[38px] max-w-[136px] encord_max_width_mobile lg:max-w-[191px] object-scale-down"
                src={slice?.primary?.encord_logo?.url}
                alt={slice?.primary?.encord_logo?.alt || "Encord Logo"}
              />
              <img
                className="h-[35px] w-9 sm:w-[71px] mx-5 lg:max-w-[191px] object-scale-down competitor_icon"
                src={slice?.primary?.competitor_logo?.url}
                alt={slice?.primary?.competitor_logo?.alt || "Competitor Logo"}
              />
            </div>
          </div>

          <div className="flex flex-col-reverse lg:flex-row lg:items-center justify-between lg:pl-6 px-1.5 lg:pr-12 border-b">
            {slice?.items[0]?.section_header_title?.text && (
              <div
                dangerouslySetInnerHTML={{
                  __html: slice.items[0].section_header_title.html,
                }}
                className="text-xl sm:text-[22px] pb-3 md:pb-0 tracking-tighter_1 font-inter font-medium text-gray-2700"
              />
            )}
            <div className="hidden lg:flex justify-between pt-1.5 pb-3 sm:pt-6 sm:pb-3 lg:py-6">
              <img
                className="h-[38px] lg:w-[191px] object-scale-down"
                src={slice?.primary?.encord_logo?.url}
                alt={slice?.primary?.encord_logo?.alt || "Encord Logo"}
              />
              <img
                className="h-[35px] lg:w-[191px] object-scale-down"
                src={slice?.primary?.competitor_logo?.url}
                alt={slice?.primary?.competitor_logo?.alt || "Competitor Logo"}
              />
            </div>
          </div>
          <div>
            {slice?.items?.map((value, index, array) => {
              const nextItemHasHeader =
                array[index + 1]?.section_header_title?.text;

              return index !== 0 ? (
                <div
                  key={index}
                  className={` ${nextItemHasHeader ? "" : "border-b border-[#E6E6E6]"} ${
                    !value?.section_header_title?.text
                      ? "hover:bg-[#f5f5f5] px-4 lg:pl-9 lg:pr-14 py-3"
                      : "px-1.5 pt-9 pb-3 lg:pl-3 sm:pt-6 sm:pb-3"
                  }`}
                >
                  {value?.section_header_title?.text && (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: value.section_header_title.html,
                      }}
                      className="text-xl sm:text-[22px] tracking-tighter_1 font-inter font-medium text-gray-2700"
                    />
                  )}
                  <div className="flex gap-3">
                    {isDesktop && value?.feature_name?.text && (
                      <FeatureBullet />
                    )}
                    {value?.feature_name?.text && (
                      <div className="flex flex-col gap-1.5 lg:gap-0 lg:flex-row justify-between w-full">
                        <p className="text-sm tracking-tight_1 sm:text-base max-w-[400px] xl:max-w-[570px] font-normal text-gray-2500 sm:-tracking-[0.32px]">
                          {value?.feature_name?.text}
                        </p>
                        <div className="flex justify-between items-center px-6 md:px-0 w-auto lg:min-w-[191px]">
                          <div className="flex justify-center">
                            {value?.encord_column?.text === "check" ? (
                              <EncordCheck />
                            ) : value?.encord_column?.text ? (
                              <p className="text-base md:min-w-[191px] md:mx-4 lg:mx-0 text-center font-medium text-purple-1500 -tracking-[0.32px]">
                                {value.encord_column.text}
                              </p>
                            ) : (
                              !value?.section_header_title?.text && <V7Cross />
                            )}
                          </div>
                          <div className="flex justify-center px-4 md:px-0 lg:min-w-[191px]">
                            {value?.competitor_column?.text === "check" ? (
                              <V7Check />
                            ) : value?.competitor_column?.text ? (
                              <p className="text-base md:min-w-[191px] md:mx-4 lg:mx-0 text-center font-medium text-gray-1200 -tracking-[0.32px]">
                                {value.competitor_column.text}
                              </p>
                            ) : (
                              !value?.section_header_title?.text && <V7Cross />
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              ) : null;
            })}
          </div>
        </section>
      </article>
    </section>
  );
};
