import React from "react";
import VideoComponent from "../../VideoComponent";
import { Link } from "gatsby-link";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const FeatureKlimtCards = ({ data }) => {
  return (
    <>
      {data?.link?.url !== "" ? (
        <Link
          to={data?.link?.url}
          target="_blank"
          aria-label="opens in a new tab"
          className={`flex flex-col gap-3 p-3 lg:gap-5 lg:py-5 lg:px-12 rounded-[10px] card_background w-full justify-between sm:max-w-[368px] card_mobile_width ${data?.link?.url != null && "cursor-pointer"}`}
        >
          {data?.card_video?.url ? (
            <VideoComponent
              poster={HeroPoster}
              loop
              autoPlay
              muted
              className="max-h-[150px] rounded-lg"
            >
              <source src={_data?.video?.url} type="video/mp4" />
            </VideoComponent>
          ) : (
            data?.image?.url && (
              // <GatsbyImage
              //   image={getImage(data?.image)}
              //   loading="eager"
              //   fetchPriority="high"
              //   width={0}
              //   height={0}
              //   alt="stats"
              //   className="w-full max-h-[97px] lg:max-h-[150px] h-full rounded-lg"
              // />
              <img
                src={data?.image?.url}
                loading="eager"
                fetchPriority="high"
                width={0}
                height={0}
                alt="stats"
                className="w-full max-h-[97px] lg:max-h-[150px] h-full rounded-lg"
              />
            )
          )}
          <p className="text-xl font-bold leading-7 text-center text-white lg:text-2xl lg:leading-8 font-manrope tracking-tightest lg:tracking-tighter_2">
            {data?.title?.text}
          </p>
        </Link>
      ) : (
        <div
          role="button"
          tabIndex={0}
          className={`flex flex-col gap-3 p-3 lg:gap-5 lg:py-5 lg:px-12 rounded-[10px] card_background w-full justify-between sm:max-w-[368px] card_mobile_width ${data?.link?.url != "" && "cursor-pointer"}`}
        >
          {data?.card_video?.url ? (
            <VideoComponent
              poster={HeroPoster}
              loop
              autoPlay
              muted
              className="max-h-[150px] rounded-lg"
            >
              <source src={_data?.video?.url} type="video/mp4" />
            </VideoComponent>
          ) : (
            data?.image?.url && (
              // <GatsbyImage
              //   image={getImage(data?.image)}
              //   loading="eager"
              //   fetchPriority="high"
              //   width={0}
              //   height={0}
              //   alt="stats"
              //   className="w-full max-h-[97px] lg:max-h-[150px] h-full rounded-lg"
              // />
              <img
                src={data?.image?.url}
                loading="eager"
                width={0}
                height={0}
                alt="stats"
                className="w-full max-h-[97px] lg:max-h-[150px] h-full rounded-lg"
              />
            )
          )}
          <p className="text-xl font-bold leading-7 text-center text-white lg:text-2xl lg:leading-8 font-manrope tracking-tightest lg:tracking-tighter_2">
            {data?.title?.text}
          </p>
        </div>
      )}
    </>
  );
};

export default FeatureKlimtCards;
